
.label-background {
    background-color: #F59E0B;
    opacity: 1;
    padding: 5px;
    width: 80px;
    height: 30px;
    /* Add any other desired styling properties */
  }
  
  .css-1aquho2-MuiTabs-indicator {
    display: none!important;
  }
  .activeTab {
    color: #F59E0B !important;
  }
  
  .css-ttwr4n {
    position: absolute;
    height: 2px;
    bottom: 0px;
    width: 100%;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: inherit;
  }
  